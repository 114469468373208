import { TextDecoder } from 'text-encoding'
import Encoding from 'encoding-japanese'
import { splitLine } from 'Helpers/helpers'

const CsvReader = {
  read(id) {
    return new Promise((resolve, reject) => {
      const fileInput = document.getElementById(id)
      const fileReader = new FileReader()
      fileInput.onclick = () => {
        // 連続アップロードを可能にする
        fileInput.value = null
      }
      fileInput.onchange = () => {
        const file = fileInput.files[0]
        /*
        windows だとファイルタイプが取れない場合があるので一旦コメントアウト
        if (file.type !== "text/csv") {
          reject(new Error("InvalidFileType"))
        }
        */
        fileReader.readAsArrayBuffer(file)
      }
      fileReader.onload = (event) => {
        const csvData = this._getData(event.target.result)
        return resolve(csvData)
      }
      fileInput.click()
    })
  },
  _getData(uintArray) {
    const removeFileEndNewLiner = (rows) => {
      if (rows[rows.length - 1] === '') {
        rows.pop()
      }
    }

    const binString = new Uint8Array(uintArray)
    const detected = Encoding.detect(binString, 'UTF8')
    const convertType = detected ? 'UTF-8' : 'Shift_JIS'

    const decoder = new TextDecoder(convertType)
    const allRows = decoder.decode(uintArray).split(/\r?\n|\r/)
    removeFileEndNewLiner(allRows)
    const csvData = allRows.map((row) => splitLine(row))
    return csvData
  },
}

export default Object.freeze(CsvReader)
